import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import Maint from './Components/Maintenance';
import Audit from './Components/Audit';
import Supply from './Components/Supply';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Home/>}/>
        <Route path="/maint" exact element={<Maint/>}/>
        <Route path="/audit" element={<Audit/>}/>
        <Route path="/supply" element={<Supply/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
