import { Link } from "react-router-dom";

const Supply = ()=>{
    return (
        <>
            <div className="container supply" style={{height:'40vh'}}>
                <div className="logo">
                    <span></span>
                    <Link to="/"><img src="./logo2.png" alt="logo" width="70px" style={{marginRight:'1rem'}}/><img src="text-logo.png" alt="text-logo"  width="100px"/></Link>
                    <img src="./linkdin-bl.png" alt="linkdin" width="15px"/>
                </div>
                <div>
                    <ul>
                        <li id="home1"><Link to="/"><img src="./home-bl.png" alt="home" width="30px" style={{marginRight:'3rem'}}/></Link></li>
                        <li>
                            <div  style={{marginBottom:'1rem'}}>Audit Stratégique</div>
                            <div className="supply" id="s1" style={{fontSize:'1rem'}}>Supply Chain
                                <img style={{marginLeft:'6.5rem'}} src="./drop-bl.png" width="10px"/>
                                <div id="s2">
                                    <div>Audit Stratégique Supply Chain</div>
                                    <div>Audit Maturité Direction Supply Chain</div>
                                    <div>Audit Schèma Directeur Logistique</div>
                                    <div>Audit Politique Achats</div>
                                    <div>Evaluation de la configuration Stratégique Réseau</div>
                                    <div>Evaluation Gestion Stock & Inventaire</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div   style={{marginBottom:'1rem'}}>Expertises</div>
                            <div className="supply" id="s3" style={{fontSize:'1rem'}}>Supply Chain
                                <img style={{marginLeft:'6.5rem'}} src="./drop-bl.png" width="10px"/>
                                <div id="s4">
                                    <div>Chaine d'approvisionnement</div>
                                    <div>Réseau de distribution</div>
                                    <div>Transport</div>
                                    <div>Entrepôt et Centre de Distribution</div>
                                    <div>Sales & Operations SOP</div>
                                    <div>Implémentation 3PL</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div   style={{marginBottom:'1rem'}}>Amelioration Performance</div>
                            <div className="supply" id="s5" style={{fontSize:'1rem'}}>Supply Chain
                                <img style={{marginLeft:'6.5rem'}} src="./drop-bl.png" width="10px"/>
                                <div id="s6">
                                    <div>Developpement plan Directeur Approvisionnement</div>
                                    <div>Developpement plan Directeur Distribution</div>
                                    <div>Developpement Stratégie Inventaire</div>
                                    <div>Developpement Approche FRS Strategique</div>
                                    <div>Make or Buy</div>
                                    <div>Optimisation Flotte de Livraison</div>
                                    <div>Solutions Amenagement Entepot & Centre distribution</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div   style={{marginBottom:'1rem'}}>Coaching Management</div>
                            <div className="supply" id="s7" style={{fontSize:'1rem'}}>Supply Chain
                                <img style={{marginLeft:'6.5rem'}} src="./drop-bl.png" width="10px"/>
                                <div id="s8">
                                    <div>Management Transition Supply Chain</div>
                                    <div>Coaching Terrain Individuel</div>
                                    <div>Coaching Terrain d'équipe</div>
                                    <div>Coaching Directeur Supply Chain</div>
                                    <div>Coaching Supply Chain Manager</div>
                                    <div>Gestion Interimaire Supply Chain</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <img src="./email-bl.png" alt="home" width="15px"/>
                        </li>
                    </ul>
                </div>     
            </div>
            <div className="maintenance supply-who">
                <div className="container">
                    <div>
                        <h4>GIDP</h4>
                        <p>GIDP signifie <span style={{color:'rgb(23,39,61);',fontWeight:'bolder'}}>Green Intra Day Performer</span>, Une approche d'amélioration intégrée pour tirer le maximum de valeur <br/><span style={{color:'rgb(23,39,61)'}}>économique</span> & <span style={{color:'rgb(23,39,61)'}}>Opérationelle:</span></p>
                        <div className="ajouter">
                            <div>
                                <img src="./vraii.png" alt="vraii" width="10px"/>
                                <p>Connaitre votre niveau de maturité Supply Chain</p>
                            </div>
                            <div>
                                <img src="./vraii.png" alt="vraii" width="10px"/>
                                <p>Identifier vos enjeux financiers et commerciaux</p>
                            </div>
                            <div>
                                <img src="./vraii.png" alt="vraii" width="10px"/>
                                <p>Elaborer votre schéma directeur et définir votre plan d'action</p>
                            </div>
                            <div>
                                <img src="./vraii.png" alt="vraii" width="10px"/>
                                <p>Augmenter la reactivité et la disponibilité de votre Chaine de valeur</p>
                            </div>
                            <div>
                                <img src="./vraii.png" alt="vraii" width="10px"/>
                                <p>Reduire l'impact Global</p>
                            </div>
                        </div>
                    </div>
                    <img className="uman" src="gidp1.jpg" alt="photo4" width="700px"/>
                </div>
                <div className="container" style={{display:'flex',flexDirection:'column', alignItems:'flex-end'}}>
                    <h3 style={{marginTop:'4rrem'}}>Reinventing & Maximise</h3>
                    <h3>Your Supply Chain</h3>
                </div>
            </div>
            <div className="container why2 why2-supply">
                <div>
                    <h4>Qui sommes nous ?</h4>
                    <p>Green Supply & Asset est un cabinet de conseil en Entreprise, nous permettons à nos partenaires via nos consultants, nos coachs terrain professionnelle et notre approche GIDP d'acquérir un avantage compétitif durable au travers  de l'optimisation de <span style={{color:'rgb(23,39,61)'}}>la chaine d'approvisionnement</span> et l'accompagnement dans leurs enjeux <span style={{color:'rgb(23,39,61)'}}>d'approvisionnement & logistique</span></p>
                </div>
                <img src="qsmn.webp" alt="photo5" width="500px"/>
            </div>
            <div className="mission">
                <div className="container">
                    <div>
                        <h4 style={{color:'rgb(23,39,61)'}}>Notre Mission</h4>
                        <p>Nous accompagnons les entreprises en croissance dans leurs enjeux logistiques afin d'atteindre L'excellence Supply Chain et Améliorer l'avantage concurrentiel de nos partenaires.</p>
                        <p style={{marginTop:'1.5rem'}}>Dans nos projets, nous nous appuyons sur notre méthodologie innovante <span style={{color:'rgb(23,39,61)'}}>GIDP (Green Intra Day Performer)</span>.Cette méthodologie propose un modèle économique pertinent et des processus de travail basés sur les bonnes pratiques et les KPI's de pilotage.Nous accompagnons également nos partenaires à sélectionner, redéfinir, mettre en oeuvre et utiliser leur système de GMAO</p>
                    </div>
                    <img src="./nm.jpg" width="500px"/>
                </div>
            </div>
            <div className="secteurs">
                <div style={{marginBottom:'3rem',display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                    <h4><span style={{color:'rgb(23,39,61)'}}>Nos</span> <span style={{color:'#9fb95e'}}>Secteurs d'activités</span></h4>
                    <hr style={{width:'3%',textAlign:'center',border:'3px solid rgb(23,39,61)'}}/>
                </div>
                <div className="container">
                    <div id="sect5">
                        <img src="construction.jpg" alt="avion" width="200px"/>
                    </div>
                    <div id="sect6" style={{display:'none'}}>
                        <p style={{color:'#9fb95e'}}>Agrolalimentaire</p>
                    </div>
                    <div id="sect7">
                        <img src="transport.jpg" alt="avion" width="200px"/>
                    </div>
                    <div id="sect8" style={{display:'none'}}>
                        <p style={{color:'rgb(23,39,61)'}}>Transport & Distribution</p>
                    </div>
                    <div id="sect9">
                        <img src="btp.jpg" alt="avion" width="200px"/>
                    </div>
                    <div id="sect10" style={{display:'none'}}>
                        <p style={{color:'rgb(23,39,61)'}}>BTP / Materiaux de Construction</p>
                    </div>
                    <div id="sect3">
                        <img src="automobile.jpg" alt="avion" width="200px"/>
                    </div>
                    <div id="sect4" style={{display:'none'}}>
                        <p style={{color:'rgb(23,39,61)'}}>Automobile</p>
                    </div>
                    <div id="sect1">
                        <img src="avion.jpg" alt="avion" width="200px"/>
                    </div>
                    <div id="sect2" style={{display:'none'}}>
                        <p style={{color:'rgb(23,39,61)'}}>Aeronautique</p>
                    </div>
                </div>
            </div>
            <div className="valeurs container">
                <div style={{marginBottom:'3rem',display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                    <h4 style={{color:'rgb(23,39,61)', marginTop:'0'}}>Nos valeurs</h4>
                    <hr style={{width:'3%',textAlign:'center',border:'3px solid rgb(23,39,61)'}}/>
                </div>
                <div className="values">
                    <div>
                        <img src="bien.png" alt="bien" width="150px" />
                        <p>Bienveillance</p>
                    </div>
                    <div>
                        <img src="engagement.png" alt="bien" width="150px" />
                        <p>Engagement</p>
                    </div>
                    <div>
                        <img src="intelligence.png" alt="bien" width="150px" />
                        <p>Intelligence collective</p>
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="container">
                    <div style={{fontSize:'.9rem'}}>
                        <p><a style={{color:'#013b36', textDecoration:'none', fontWeight:'bold'}} href="https://greensupplyasset.com/" target="_blank">www.greensupplyasset.com</a></p>
                        <p>Téléphone : <span>07 07 07 82 83</span></p>
                        <p>Email : h.k@greensupplyasset.com</p>
                        <p>Trois Palmiers, N°2 Imm 46 Sidi Maarouf, Casablanca, Maroc</p>
                        <img src="./linkdin-bl.png" alt="linkdin" width="25px"/>
                    </div>
                    <div>
                        <img src="logo2.png" width="100px"/>
                    </div>
                    <div className="footer-divs" style={{color:'rgb(23,39,61)'}}>
                        <h5 style={{fontWeight:'bold'}}>Supply Chain</h5>
                        <p>Audit Supply</p>
                        <p>Accompagnement</p>
                        <p>Coaching Terrain</p>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <p>Green Supply Asset Conseil 2023 . Tous droits réservés</p>
            </div>
        </>
    );
}
export default Supply;