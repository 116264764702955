const Audit = ()=>{
    return(
        <>
            <div className="container" style={{height:'35vh'}}>
                    <div className="logo">
                        <span></span>
                        <div>
                            <img src="logo2.png" width="40px"/>
                            <h6>Cabinet de conseil en Maintenance & Asset Management</h6>
                        </div>
                        <img src="./linkdin-gr.png" alt="linkdin" width="15px"/>
                    </div>
                    <div>
                        <ul>
                            <li style={{color:'#9fb95e'}}>GIAM</li>
                            <li>
                                <div><a href="/audit" style={{textDecoration:'none', color:'#9fb95e'}}>Audit Stratégique</a></div>
                            </li>
                            <li>
                                <div>Expertises</div>
                            </li>
                            <li>
                                <div>Amelioration Performance</div>
                            </li>
                            <li>
                                <div>Coaching Management</div> 
                            </li>
                            <li>
                                <img src="./email-gr.png" alt="home" width="15px"/>
                            </li>
                        </ul>
                    </div>  
                </div>
                <div className="img"></div>
                <div className="container p">
                    <p>Notre Savoir faire dans le domaine de la maintenance industrielle nous a permis de developper des outils permettant de positionner</p>
                    <p>Votre service maintenance par rapport aux bonnes pratiques de votre domaines d'activite</p>
                    <p>Deux Livrables sont délivres dans cette hotos: La strategie, politique & Plan d'action Maintenance & Asset</p>
                </div>
                <div className="rein">
                    <div className="photo">
                        <img src="./logo2.png" width="100px"/>
                        <h1>Reinventing & Maximise your Asset Value</h1>
                    </div>
                    <div className="info">
                        <div>
                            <p>Consultants en Maintenance industrielle & Gestion d'actif</p>
                            <p>Nos Consultant evaluent la fonction en realisant des entretiens individuels et operationnels des differents services et une evaluation de l'état de l'actif industriel</p>
                        </div>
                        <div>
                            <p>Audit Stratégique & Accompagnement</p>
                            <div className="vies">
                                <div>
                                    <img width="30px" src="vrai.png" alt="vrai"/>
                                    <span>Positionnement</span>
                                </div>
                                <div>
                                    <img width="30px" src="vrai.png" alt="vrai"/>
                                    <span>Audit Maintenance</span>
                                </div>
                                <div>
                                    <img width="30px" src="vrai.png" alt="vrai"/>
                                    <span>Stratégie Maintenance</span>
                                </div>
                                <div>
                                    <img width="30px" src="vrai.png" alt="vrai"/>
                                    <span>Accompagnement Schema Directeur</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p>Expertises Maintenance & Asset</p>
                            <div className="vies">
                                <div>
                                    <img width="30px" src="vrai.png" alt="vrai"/>
                                    <span>Approche GIAM service à la production</span>
                                </div>
                                <div>
                                    <img width="30px" src="vrai.png" alt="vrai"/>
                                    <span>Implementation fonction Methodes</span>
                                </div>
                                <div>
                                    <img width="30px" src="vrai.png" alt="vrai"/>
                                    <span>Implementation GMAO Maintenance</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p>Amelioration Performance Maintenance & Asset</p>
                            <div className="vies">
                                <div>
                                    <img width="30px" src="vrai.png" alt="vrai"/>
                                    <span>Optimisation du cout de gestion d'actif</span>
                                </div>
                                <div>
                                    <img width="30px" src="vrai.png" alt="vrai"/>
                                    <span>Optimisation de la gestion des Arrêts</span>
                                </div>
                                <div>
                                    <img width="30px" src="vrai.png" alt="vrai"/>
                                    <span>Optimisation de la gestion des PDR</span>
                                </div>
                                <div>
                                    <img width="30px" src="vrai.png" alt="vrai"/>
                                    <span>Maintenance Conditionelle</span>
                                </div>
                                <div>
                                    <img width="30px" src="vrai.png" alt="vrai"/>
                                    <span>Maintenance Preventive</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p>Coaching Management Maintenance & Asset</p>
                            <div className="vies">
                                <div>
                                    <img width="30px" src="vrai.png" alt="vrai"/>
                                    <span>Coaching Individuels & Collectif</span>
                                </div>
                                <div>
                                    <img width="30px" src="vrai.png" alt="vrai"/>
                                    <span>Coaching Manager Maintenance</span>
                                </div>
                                <div>
                                    <img width="30px" src="vrai.png" alt="vrai"/>
                                    <span>Formation Metiers Maintenance</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}
export default Audit;