import { Link } from "react-router-dom";

const Home = ()=>{
    return(
        <>
            <div className="container" style={{height:'40vh'}}>
                <div className="logo">
                    <span></span>
                    <Link to="/"><img src="./logo2.png" alt="logo" width="70px" style={{marginRight:'1rem'}}/><img src="text-logo.png" alt="text-logo"  width="100px"/></Link>
                    <img src="./linkdin-gr.png" alt="linkdin" width="15px"/>
                </div>
                <div>
                    <ul>
                        <li id="home1"><Link to="/"><img src="./home-gr.png" alt="home" width="30px" style={{marginRight:'3rem'}}/></Link>
                            <div id="home2" style={{display:'none',marginRight:'2rem'}}>
                                <Link style={{textDecoration:'none'}} to="/maint"><div style={{color:'#9fb95e',fontSize:'1rem'}}>GIAM</div></Link>
                                <Link style={{textDecoration:'none', color:'rgb(23,39,61)'}} to="/supply"><div style={{fontSize:'1rem'}}>GIDP</div></Link>
                            </div>
                        </li>
                        <li>
                            <div  style={{marginBottom:'1rem'}}>Audit Stratégique</div>
                            <Link to="/maint" className="link" style={{marginBottom:'1rem'}}>
                                <div id="one" style={{fontSize:'.9rem'}}>Maintenance & Asset
                                <img style={{marginLeft:'3rem'}} src="./drop-gr.png" width="15px"/>
                                    <div id="two">
                                        <div>Audit Stratégique Maintenance industrielle</div>
                                        <div>Audit Stratégique Gestion d'actif industriel</div>
                                        <div>Audit Maturité Maintenance</div>
                                        <div>Audit Maturité Méthodes Maintenance</div>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/supply" className="link">
                                <div className="supply" id="s1" style={{fontSize:'.9rem'}}>Supply Chain
                                    <img style={{marginLeft:'6.5rem'}} src="./drop-bl.png" width="15px"/>
                                    <div id="s2">
                                        <div>Audit Stratégique Supply Chain</div>
                                        <div>Audit Maturité Direction Supply Chain</div>
                                        <div>Audit Schèma Directeur Logistique</div>
                                        <div>Audit Politique Achats</div>
                                        <div>Evaluation de la configuration Stratégique Réseau</div>
                                        <div>Evaluation Gestion Stock & Inventaire</div>
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <div   style={{marginBottom:'1rem'}}>Expertises</div>
                            <div id="three" style={{fontSize:'.9rem' ,marginBottom:'1rem'}}>Maintenance & Asset
                            <img style={{marginLeft:'3.5rem'}} src="./drop-gr.png" width="15px"/>
                                <div id="four">
                                    <div>Stratégie Maintenance Industrielle</div>
                                    <div>Implémentation Structure Maintenance</div>
                                    <div>Implémentation fonction méthodes</div>
                                    <div>Implémentation GMAO Maintenance</div>
                                    <div>Externalisation Maintenance</div>
                                </div>
                            </div>
                            <div className="supply" id="s3" style={{fontSize:'1rem'}}>Supply Chain
                                <img style={{marginLeft:'6.5rem'}} src="./drop-bl.png" width="15px"/>
                                <div id="s4">
                                    <div>Chaine d'approvisionnement</div>
                                    <div>Réseau de distribution</div>
                                    <div>Transport</div>
                                    <div>Entrepôt et Centre de Distribution</div>
                                    <div>Sales & Operations SOP</div>
                                    <div>Implémentation 3PL</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div   style={{marginBottom:'1rem'}}>Amelioration Performance</div>
                            <div id="five"  style={{fontSize:'.9rem' ,marginBottom:'1rem'}}>Maintenance & Asset
                            <img style={{marginLeft:'3.5rem'}} src="./drop-gr.png" width="15px"/>
                                <div id="six">
                                    <div>Optimisation coût de gestion d'actif</div>
                                    <div>Optimisation gestion des Arrêts</div>
                                    <div>Optimisation gestion des PDR</div>
                                    <div>Maintenance Conditionelle</div>
                                    <div>Maintenance Preventive</div>
                                </div>
                            </div>
                            <div className="supply" id="s5" style={{fontSize:'1rem'}}>Supply Chain
                                <img style={{marginLeft:'6.5rem'}} src="./drop-bl.png" width="15px"/>
                                <div id="s6">
                                    <div>Développement plan Directeur Approvisionnement</div>
                                    <div>Développement plan Directeur Distribution</div>
                                    <div>Développement Stratégie Inventaire</div>
                                    <div>Développement Approche FRS Stratégique</div>
                                    <div>Make or Buy</div>
                                    <div>Optimisation Flotte de Livraison</div>
                                    <div>Solutions Amenagement Entepôt & Centre distribution</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div   style={{marginBottom:'1rem'}}>Coaching Management</div>
                            <div id="seven"  style={{fontSize:'.9rem' ,marginBottom:'1rem'}}>Maintenance & Asset
                                <img style={{marginLeft:'3.5rem'}} src="./drop-gr.png" width="15px"/>
                                <div id="eight">
                                    <div>Coaching Directeur Maintenance</div>
                                    <div>Coaching Directeur Industriel</div>
                                    <div>Coaching Responsable Méthodes</div>
                                    <div>Coaching Responsable Fiabilité</div>
                                    <div>Coaching Responsable Planification</div>
                                    <div>Management de transition en Interim</div>
                                </div>
                            </div>  
                            <div className="supply" id="s7" style={{fontSize:'1rem'}}>Supply Chain
                                <img style={{marginLeft:'6.5rem'}} src="./drop-bl.png" width="15px"/>
                                <div id="s8">
                                    <div>Management Transition Supply Chain</div>
                                    <div>Coaching Terrain Individuel</div>
                                    <div>Coaching Terrain d'équipe</div>
                                    <div>Coaching Directeur Supply Chain</div>
                                    <div>Coaching Supply Chain Manager</div>
                                    <div>Gestion Interimaire Supply Chain</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <img src="./email-gr.png" alt="home" width="15px"/>
                        </li>
                    </ul>
                </div>     
            </div>
            <div className="acceuil">
                <div className="container">
                    <div>
                    </div>
                    <div style={{backgroundColor:'#9fb95e'}}>
                        <h1 style={{textAlign:'center',}}>Cabinet Conseil & Consultants en </h1>
                        <p style={{ color:'white'}}>Maintenance & Gestion D’actif Industriel</p>
                    </div>
                </div>
            </div>
            <div className="container who-are-we">
                <img src="AssetMaintenance2.jpg" alt="photo2"/>
                <div>
                    <h2>Qui sommes nous?</h2>
                    <p><span style={{color:'#9fb95e'}}>GSAC</span> est un cabinet de conseil en Entreprise, nous permettons à nos partenaires via nos consultants, nos coachs terrain professionnelle et notre approche <span style={{color:'#9fb95e'}}>GIAM</span> d'acquerir un avantage compétitif durable au travers de l'optimisation de <span>la maintenance </span>et de <span>la gestion d'actif industriel</span></p>
                    <h3 style={{paddingTop:'2rem'}}>Reiventing, Maximise</h3>
                    <h3>Your Asset & Supply Value</h3>
                </div>
            </div>
            <div className="acceuil2">
                <div className="container">
                    <div>
                    </div>
                    <div style={{backgroundColor:'rgb(23,39,61)'}}>
                        <h1 style={{textAlign:'center',}}>Cabinet Conseil & Consultants en </h1>
                        <p style={{ color:'white'}}>Supply Chain</p>
                    </div>
                </div>
            </div>
            <div className="container who-are-we">
                <img src="C.jpg" alt="photo2"/>
                <div>
                    <h2 style={{color:'rgb(23,39,61)'}}>Qui sommes nous?</h2>
                    <p><span style={{color:'rgb(23,39,61)'}}>GSAC</span> est un cabinet de conseil en Entreprise, nous permettons à nos partenaires via nos consultants, nos coachs terrain professionnelle et notre approche <span style={{color:"rgb(23,39,61)"}}>GIDP</span>  d'acquerir un avantage compétitif durable au travers de l'optimisation de <span style={{color:'rgb(23,39,61)'}}>la chaine d'approvisionnement</span> et l'accompagnement dans leurs enjeux <span style={{color:'rgb(23,39,61)'}}>d'approvisionnement & logistique</span></p>
                    <h3 style={{paddingTop:'2rem'}}>Reiventing, Maximise</h3>
                    <h3>Your Asset & Supply Value</h3>
                </div>
            </div>
            <div className="secteurs">
                <div style={{marginBottom:'3rem',display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                    <h4><span style={{color:'rgb(23,39,61)'}}>Nos</span> <span style={{color:'#9fb95e'}}>Secteurs d'activités</span></h4>
                    <hr style={{width:'3%',textAlign:'center',border:'3px solid rgb(23,39,61)'}}/>
                </div>
                <div className="container">
                    <div id="sect5">
                        <img src="construction.jpg" alt="avion" width="200px"/>
                    </div>
                    <div id="sect6" style={{display:'none'}}>
                        <p style={{color:'#9fb95e'}}>Agrolalimentaire</p>
                    </div>
                    <div id="sect7">
                        <img src="transport.jpg" alt="avion" width="200px"/>
                    </div>
                    <div id="sect8" style={{display:'none'}}>
                        <p style={{color:'rgb(23,39,61)'}}>Transport & Distribution</p>
                    </div>
                    <div id="sect9">
                        <img src="btp.jpg" alt="avion" width="200px"/>
                    </div>
                    <div id="sect10" style={{display:'none'}}>
                        <p style={{color:'rgb(23,39,61)'}}>BTP / Materiaux de Construction</p>
                    </div>
                    <div id="sect3">
                        <img src="automobile.jpg" alt="avion" width="200px"/>
                    </div>
                    <div id="sect4" style={{display:'none'}}>
                        <p style={{color:'rgb(23,39,61)'}}>Automobile</p>
                    </div>
                    <div id="sect1">
                        <img src="avion.jpg" alt="avion" width="200px"/>
                    </div>
                    <div id="sect2" style={{display:'none'}}>
                        <p style={{color:'rgb(23,39,61)'}}>Aeronautique</p>
                    </div>
                </div>
            </div>
            <div className="valeurs container">
                <div style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                    <h4 style={{marginBottom:'0', paddingBottom:'0'}}>Nos valeurs</h4>
                    <hr style={{width:'3%',textAlign:'center',border:'3px solid rgb(23,39,61)'}}/>
                </div>
                <div className="values">
                    <div>
                        <img src="bien.png" alt="bien" width="150px"/>
                        <p>Bienveillance</p>
                    </div>
                    <div>
                        <img src="engagement.png" alt="bien" width="150px" style={{marginBottom:'4.5rem'}}/>
                        <p>Engagement</p>
                    </div>
                    <div>
                        <img src="intelligence.png" alt="bien" width="150px" />
                        <p>Intelligence collective</p>
                    </div>
                </div>
            </div>
            <div className="contactez-nous container">
                <div className="c1">
                    <div className="c2-txt1">
                        <h2>VOUS AVEZ UN PROJET ?</h2>
                        <button><a style={{color:'white', textDecoration:'none'}} href="mailto:h.k@greensupplyasset.com">Contactez-nous</a></button>
                    </div>
                    <div className="c2-img1"></div>
                </div>
                <div className="c1">
                    <div className="c2-img2"></div>
                    <div  className="c2-txt2">
                        <h2>VOUS VOULEZ DEVENIR CONSULTANT ?</h2>
                        <button>REJOIGNER NOTRE EQUIPE</button>
                    </div>
                </div>
            </div>
            <div className="footer"   style={{backgroundColor:'#F7F7F7'}}>
                <div className="container">
                    <div style={{fontSize:'.9rem'}}>
                        <p><a style={{color:'#013b36', textDecoration:'none', fontWeight:'bold'}} href="https://greensupplyasset.com/" target="_blank">www.greensupplyasset.com</a></p>
                        <p>Téléphone : <span>07 07 07 82 83</span></p>
                        <p>Email : h.k@greensupplyasset.com</p>
                        <p>Trois Palmiers, N°2 Imm 46 Sidi Maarouf, Casablanca, Maroc</p>
                        <img src="./linkdin-gr.png" alt="linkdin" width="25px"/>
                    </div>
                    <div>
                        <img src="logo2.png" width="100px"/>
                    </div>
                    <div className="footer-divs" style={{color:'#9fb95e'}}>
                        <h5 style={{fontWeight:'bold'}}>Maintenance & Asset</h5>
                        <p>Audit maintenance</p>
                        <p>Accompagnement</p>
                        <p>Coaching Terrain</p>
                    </div>
                    <div className="footer-divs" style={{color:'rgb(23,39,61)'}}>
                        <h5 style={{fontWeight:'bold'}}>Supply Chain</h5>
                        <p>Audit Supply</p>
                        <p>Accompagnement</p>
                        <p>Coaching Terrain</p>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <p>Green Supply Asset Conseil 2023 . Tous droits réservés</p>
            </div>
        </>
    );
}
export default Home;