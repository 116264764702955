import { Link } from "react-router-dom";

const Maint = ()=>{
    return(
        <>
           <div className="container" style={{height:'40vh'}}>
                <div className="logo">
                    <span></span>
                    <Link to="/"><img src="./logo2.png" alt="logo" width="70px" style={{marginRight:'1rem'}}/><img src="text-logo.png" alt="text-logo"  width="100px"/></Link>
                    <img src="./linkdin-gr.png" alt="linkdin" width="15px"/>
                </div>
                <div>
                    <ul>
                        <li id="home1"><Link to="/"><img src="./home-gr.png" alt="home" width="30px" style={{marginRight:'3rem'}}/></Link>
                            <div id="home2" style={{display:'none',marginRight:'2rem'}}>
                                <Link style={{textDecoration:'none'}} to="/maint"><div style={{color:'#9fb95e',fontSize:'1rem'}}>GIAM</div></Link>
                                <Link style={{textDecoration:'none', color:'rgb(23,39,61)'}} to="/supply"><div style={{fontSize:'1rem'}}>GIDP</div></Link>
                            </div>
                        </li>
                        <li>
                            <div  style={{marginBottom:'1rem'}}>Audit Stratégique</div>
                            <Link to="/maint" className="link" style={{marginBottom:'1rem'}}>
                                <div id="one" style={{fontSize:'.9rem'}}>Maintenance & Asset
                                <img style={{marginLeft:'3rem'}} src="./drop-gr.png" width="15px"/>
                                    <div id="two">
                                        <div>Audit Stratégique Maintenance industrielle</div>
                                        <div>Audit Stratégique Gestion d'actif industriel</div>
                                        <div>Audit Maturité Maintenance</div>
                                        <div>Audit Maturité Méthodes Maintenance</div>
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <div   style={{marginBottom:'1rem'}}>Expertises</div>
                            <div id="three" style={{fontSize:'.9rem' ,marginBottom:'1rem'}}>Maintenance & Asset
                            <img style={{marginLeft:'3.5rem'}} src="./drop-gr.png" width="15px"/>
                                <div id="four">
                                    <div>Stratégie Maintenance Industrielle</div>
                                    <div>Implémentation Structure Maintenance</div>
                                    <div>Implémentation fonction méthodes</div>
                                    <div>Implémentation GMAO Maintenance</div>
                                    <div>Externalisation Maintenance</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div   style={{marginBottom:'1rem'}}>Amelioration Performance</div>
                            <div id="five"  style={{fontSize:'.9rem' ,marginBottom:'1rem'}}>Maintenance & Asset
                            <img style={{marginLeft:'3.5rem'}} src="./drop-gr.png" width="15px"/>
                                <div id="six">
                                    <div>Optimisation coût de gestion d'actif</div>
                                    <div>Optimisation gestion des Arrêts</div>
                                    <div>Optimisation gestion des PDR</div>
                                    <div>Maintenance Conditionelle</div>
                                    <div>Maintenance Preventive</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div   style={{marginBottom:'1rem'}}>Coaching Management</div>
                            <div id="seven"  style={{fontSize:'.9rem' ,marginBottom:'1rem'}}>Maintenance & Asset
                                <img style={{marginLeft:'3.5rem'}} src="./drop-gr.png" width="15px"/>
                                <div id="eight">
                                    <div>Coaching Directeur Maintenance</div>
                                    <div>Coaching Directeur Industriel</div>
                                    <div>Coaching Responsable Méthodes</div>
                                    <div>Coaching Responsable Fiabilité</div>
                                    <div>Coaching Responsable Planification</div>
                                    <div>Management de transition en Interim</div>
                                </div>
                            </div>  
                        </li>
                        <li>
                            <img src="./email-gr.png" alt="home" width="15px"/>
                        </li>
                    </ul>
                </div>     
            </div>
            <div className="maintenance">
                <div className="container">
                    <div>
                        <h4>GIAM</h4>
                        <p>GIAM signifie Green Integreted Asset Management, Une approche pour tirer le maximum de valeur économique</p>
                        <p>Dans nos projets, nous nous appuyons sur notre méthodologie innovante <span style={{color:'#9fb95e'}}>GIAM</span><span> (Green Integrated Asset Management).</span> Cette méthodologie propose un modèle économique pertinent et des processus de travail basés sur les bonnes pratiques et les KPI's de pilotage. Nous accompagnons également nos partenaires à sélectionner, redéfinir, mettre en oeuvre et utiliser efficacement leur système de GMAO.</p>
                    </div>
                    <img src="maint.jpg" alt="photo4" width="500px"/>
                </div>
                <div className="container" style={{display:'flex',flexDirection:'column', alignItems:'flex-end'}}>
                    <h3 style={{paddingTop:'.5rem', color:'#9fb95e'}}>Reiventing, Maximise</h3>
                    <h3 style={{color:'#9fb95e'}}>Your Asset Value</h3>
                </div>
            </div>
            <div className="container why2">
                <div>
                    <h4>Qui sommes nous ?</h4>
                    <p>Green Supply & Asset est un cabinet de conseil, leader en Maintenance et Gestion des Actifs. Nous accompagnons les entreprises afin de professionnaliser leur département de maintenance, à Transformer les directions Technique & Maintenance d'un centre de coûts à une fonction commerciale qui ajoute continuellement de la valeur au résultat opérationnel;<span> à Ameliorer la disponibilité technique, la réduction des coûts de Maintenance, l'amélioration de la sécurité et la prolongation de la durée de vie de l'actif industriel.</span></p>
                </div>
                <img src="hadi.jpg" alt="photo5" width="500px"/>
            </div>
            <div className="mission">
                <div className="container">
                    <div>
                        <h4>Notre Mission</h4>
                        <p>Notre mission est de maintenir la compétitivité de l'industrie malgré le vieillissement de ses actifs. La méthodologie <span style={{fontWeight:'bolder', color:'#9fb95e'}}>GIAM</span> a fait ses preuves en tant qu'approche d'amélioration intégrée, grâce à laquelle l'organisation de la gestion de l'actif industriel et de la maintenance peut faire des choix fondés pour créer de la valeur ajoutée. La seule façon de rester compétitif est d'être plus intelligent en matière de Maintencance et de Gestion des actifs.</p>
                    </div>
                    <img src="./mission.jpg" width="500px"/>
                </div>
            </div>
            <div className="secteurs">
                <div style={{marginBottom:'3rem',display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                    <h4><span style={{color:'rgb(23,39,61)'}}>Nos</span> <span style={{color:'#9fb95e'}}>Secteurs d'activités</span></h4>
                    <hr style={{width:'3%',textAlign:'center',border:'3px solid rgb(23,39,61)'}}/>
                </div>
                <div className="container">
                    <div id="sect5">
                        <img src="construction.jpg" alt="avion" width="200px"/>
                    </div>
                    <div id="sect6" style={{display:'none'}}>
                        <p style={{color:'#9fb95e'}}>Agrolalimentaire</p>
                    </div>
                    <div id="sect7">
                        <img src="transport.jpg" alt="avion" width="200px"/>
                    </div>
                    <div id="sect8" style={{display:'none'}}>
                        <p style={{color:'rgb(23,39,61)'}}>Transport & Distribution</p>
                    </div>
                    <div id="sect9">
                        <img src="btp.jpg" alt="avion" width="200px"/>
                    </div>
                    <div id="sect10" style={{display:'none'}}>
                        <p style={{color:'rgb(23,39,61)'}}>BTP / Materiaux de Construction</p>
                    </div>
                    <div id="sect3">
                        <img src="automobile.jpg" alt="avion" width="200px"/>
                    </div>
                    <div id="sect4" style={{display:'none'}}>
                        <p style={{color:'rgb(23,39,61)'}}>Automobile</p>
                    </div>
                    <div id="sect1">
                        <img src="avion.jpg" alt="avion" width="200px"/>
                    </div>
                    <div id="sect2" style={{display:'none'}}>
                        <p style={{color:'rgb(23,39,61)'}}>Aeronautique</p>
                    </div>
                </div>
            </div>
            <div className="valeurs container">
                <h4>Nos valeurs</h4>
                <div className="values">
                    <div>
                        <img src="bien.png" alt="bien" width="150px"/>
                        <p>Bienveillance</p>
                    </div>
                    <div>
                        <img src="engagement.png" alt="bien" width="150px" style={{marginBottom:'4.5rem'}}/>
                        <p>Engagement</p>
                    </div>
                    <div>
                        <img src="intelligence.png" alt="bien" width="150px" />
                        <p>Intelligence collective</p>
                    </div>
                </div>
            </div>
            <div className="footer"  style={{backgroundColor:'#F7F7F7'}}>
                <div className="container">
                    <div style={{fontSize:'.9rem'}}>
                        <p><a style={{color:'#013b36', textDecoration:'none', fontWeight:'bold'}} href="https://greensupplyasset.com/" target="_blank">www.greensupplyasset.com</a></p>
                        <p>Téléphone : <span>07 07 07 82 83</span></p>
                        <p>Email : h.k@greensupplyasset.com</p>
                        <p>Trois Palmiers, N°2 Imm 46 Sidi Maarouf, Casablanca, Maroc</p>
                        <img src="./linkdin-gr.png" alt="linkdin" width="25px"/>
                    </div>
                    <div>
                        <img src="logo2.png" width="100px"/>
                    </div>
                    <div className="footer-divs" style={{color:'#9fb95e'}}>
                        <h5 style={{fontWeight:'bold'}}>Maintenance & Asset</h5>
                        <p>Audit maintenance</p>
                        <p>Accompagnement</p>
                        <p>Coaching Terrain</p>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <p>Green Supply Asset Conseil 2023 . Tous droits réservés</p>
            </div>
        </>
    )
}
export default Maint;